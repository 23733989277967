import React from "react";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import * as styles from "../styles/team.module.css";

export default function Team() {
  const team = [
    {
      photo: "/img/team/Srini.png",
      name: "Srini Chappidi",
      designation: "Founder",
      info: "20+ Yrs of experience in Web Development and Salesforce consulting services.",
      link: "https://www.linkedin.com/in/srini-chappidi/",
    },
    {
      photo: "/img/team/Abishake.png",
      name: "Abishake",
      designation: "Full Stack Developer",
      info: "Full Stack Developer (3+ Yrs) | JAMStack Enthusiast | Blockchain Enthusiast | Pythonista",
      link: "https://www.linkedin.com/in/abi-shake/",
    },
    {
      photo: "/img/team/Srinivas.png",
      name: "Srinivas Kunthipuram",
      designation: "Salesforce Admin",
      info: "Experienced in Salesforce Lightning and Sales cloud implementation.",
      link: "https://www.linkedin.com/in/srinivas-kunthipuram-5497589",
    },
    {
      photo: "/img/team/Saichand.png",
      name: "Saichand Ballagiri",
      designation: "Salesforce Developer",
      info: "Salesforce Developer (1 Yr) | Working as Salesforce Developer.  ",
      link: "https://www.linkedin.com/in/saichand-ballagiri-9b2312122/",
    },
    {
      photo: "/img/team/Raghava.png",
      name: "Raghava Raja",
      designation: "Salesforce Admin Intern",
      info: "Working as Salesforce Admin Intern.",
      link: "https://www.linkedin.com/in/raghava-raja-6b3407233",
    },
    {
      photo: "/img/team/Periyakaruppan.png",
      name: "Periyakaruppan Ganesan",
      designation: "Salesforce Admin Intern",
      info: "Working as Salesforce Admin Intern.",
      link: "https://www.linkedin.com/in/periyakaruppan-ganesan-037185160/",
    },
  ];
  return (
    <Layout>
      {/* Meta tags */}
      <Helmet>
        <title>Meet The Team</title>
        <meta name="description" content="Meeting our experts." />
      </Helmet>
      <div className={styles.main}>
        {/* Top Information */}
        <div className={styles.headSection}>
          {/* Heading */}
          <div className={styles.headTitle}>
            <div>Team &nbsp;</div>
            <div style={{ color: "#345389" }}>D</div>
            <div style={{ color: "#ff6f61" }}>B</div>
            <div style={{ color: "#345389" }}>T</div>
          </div>
          {/* Team slogan */}
          <div className={styles.headSlogan}>
            The team with potential to take your <br /> business &amp; ideas to
            the next level.
          </div>
        </div>

        {/* Info cards */}
        <div className={styles.cardsContainer}>
          {team.map((i) => (
            <div className={"card " + styles.mainCard} key={i.name}>
              <div className="card-image">
                <figure>
                  <img
                    alt={i.name}
                    src={i.photo}
                    height="160px"
                    width="160px"
                    className={styles.cardImg}
                  />
                </figure>
              </div>
              <div>
                <div className={styles.cardName}>{i.name}</div>
                <div className={styles.cardDesig}>{i.designation}</div>
                <div className={styles.cardInfo}>{i.info}</div>
              </div>
              <hr className={styles.cardHr} />
              <div className={styles.cardLinkBox}>
                <a
                  href={i.link}
                  target="_blank"
                  className={styles.cardLink}
                  rel="noreferrer"
                >
                  Visit Profile
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M16.004 9.414l-8.607 8.607-1.414-1.414L14.589 8H7.004V6h11v11h-2V9.414z" />
                  </svg>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
}
