// extracted by mini-css-extract-plugin
export var cardDesig = "team-module--cardDesig--3l9Jn";
export var cardHr = "team-module--cardHr--+2sKu";
export var cardImg = "team-module--cardImg--G7fT1";
export var cardInfo = "team-module--cardInfo--xi1Zq";
export var cardLink = "team-module--cardLink--5yBit";
export var cardLinkBox = "team-module--cardLinkBox--9ofN7";
export var cardName = "team-module--cardName--YDV-L";
export var cardsContainer = "team-module--cardsContainer--OI49A";
export var headSection = "team-module--headSection--u2Q6z";
export var headSlogan = "team-module--headSlogan--pPc2v";
export var headTitle = "team-module--headTitle--Hq4vD";
export var main = "team-module--main--FpUyp";
export var mainCard = "team-module--mainCard--KgJkn";